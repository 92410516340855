<template>
  <div class="sp-common-width-narrow py-5">
    html2canvasを使用した画像キャプチャで、textareaのtext-wrapが効かないので、<br>
    contenteditableを有効にしたdivで疑似テキストエリアを作った。というやつです。
    <br><br>
    <li>textarea</li>
    <v-capture :visible="true">
      <v-textarea v-model="text" outlined label="textarea"></v-textarea>
    </v-capture>

    <li>div</li>
    <v-capture :visible="true">
      <v-textarea-div 
        v-model="text"
        height="100px"
        label="textarea"
      />
    </v-capture>
  </div>
</template>

<script>
import VCapture from "../../components/atomic/atoms/VCapture.vue";
import VTextareaDiv from "../../components/atomic/atoms/VTextareaDiv.vue";

export default {
  components: {
    VCapture,
    VTextareaDiv,
  },

  data() {
    return {
      text: "初期値"
    }
  }
};
</script>