<template>
  <div>
    <div class="text-caption ml-3 mb-1">{{ label }}</div>
    <!-- 参照エリアでの表示切替ではvuetify標準のクラスを上書きするため -->
    <!-- vuetifyのクラスを使用してスタイリングしています -->
    <div
      class="v-input v-textarea v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined"
    >
      <div class="v-input__control">
        <div class="v-input__slot">
          <fieldset
            aria-hidden="true"
            :class="{ 'primary--text': isFocus }"
            :style="isFocus ? active : ''"
          ></fieldset>
          <div class="v-text-field__slot">
            <div
              contenteditable="true"
              class="content"
              :style="style"
              :class="classContent"
              ref="content"
              @focus="isFocus = true"
              @blur="isFocus = false"
              @input="input"
            >
              {{ initVal }}
            </div>
          </div>
        </div>
        <div class="v-text-field__details">
          <div class="v-messages theme--light">
            <div class="v-messages__wrapper"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    label: String,
    maxHeight: String,
    height: String,
  },

  data() {
    return {
      initVal: "",
      isFocus: false,

      active: {
        "border-width": "2px",
      },
    };
  },

  computed: {
    style() {
      let rtn = {};

      if (this.maxHeight) rtn["max-height"] = this.maxHeight;
      if (this.height) rtn["height"] = this.height;

      return rtn;
    },

    classContent() {
      let rtn = "";
      
      if(this.maxHeight || this.height) {
        rtn = `${rtn} scrollable`;
      }

      return rtn;
    }
  },

  methods: {
    input(e) {
      this.$emit("input", e.target.innerText);
    },
  },

  mounted() {
    this.$refs.content.innerText = this.value;
  },

  watch: {
    value(to) {
      if (this.isFocus) return;
      this.$refs.content.innerText = this.value;

    }
  }

};
</script>

<style lang="scss">
.content {
  width: 100%;
  height: fit-content;
  outline: none;
  border: none;
  overflow-wrap: break-word;
  word-wrap: normal;
  margin: 10px 0px 8px 0px;
  padding-right: 12px;
  box-sizing: border-box;
  line-height: 1.6em;
}

.scrollable {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 100px;
  }
}

fieldset {
  transition: all 1s;
}
</style>
